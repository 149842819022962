<template>
<div class="dialogBody">
    <el-tree
      :data="showData"
      :show-checkbox = "showCheckbox"
      default-expand-all
      node-key="id"
      ref="tree"
      highlight-current
      :check-strictly="true"
      :expand-on-click-node="false"
      @check="treeCheck"
      :props="prop"
    >
    </el-tree>
</div>
</template>

<script>
export default {
  name: 'AuthDialogBody',
  props: ['showData', 'showCheckbox'],
  data: function () {
    return {
      chenkKeys: [],
      checkedNodes: [],
      prop: {
        label (data, node) {
          return data.label + '-' + data.bz
        }
      }
    }
  },
  methods: {
    treeCheck: function (clickdata, chenckdata) {
      this.chenkKeys = chenckdata.checkedKeys
      this.checkedNodes = chenckdata.checkedNodes
    },
    setCheck: function (data) {
      this.chenkKeys = data
      this.$refs.tree.setCheckedKeys(data)
      this.checkedNodes = this.$refs.tree.getCheckedNodes()
    }
  }
}
</script>

<style scoped lang='scss'>
.dialogBody{
    max-height: calc(100vh - 300px);
    overflow: auto;
    :deep(.el-tree){
      background:none;
    }
}
</style>
